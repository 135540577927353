import { useEffect, useMemo, useState } from "react";

type Bundle = {
    contentName: string;
    contentLink: string;
    imageUrl: string;
}

export default function App() {

    const explodedViewBundlesViewModel: { bundles: Bundle[], nodeLink: string, totalCount: number } = (window as any).app.preloadState.explodedViewBundlesList;
    const size = explodedViewBundlesViewModel.bundles.length;
    const totalCount = explodedViewBundlesViewModel.totalCount;

    const callback = async (page: number) => {
        const response = await fetch(`/api/machinecategorybundles/${explodedViewBundlesViewModel.nodeLink}?pageNumber=${page}`, {
            method: 'GET',
            headers: {
                "Swecon-Current-Language": (window as any).app.preloadState.currentLanguage
            }
        });
        if (!response.ok) {
            throw new Error(await response.text());
        }
        return await response.json();
    }

    const numberOfDivs = size > 0 ? Math.ceil(totalCount / size) : 1;

    const mapping = Array.from(Array(numberOfDivs - 1).keys())

    return <div className="sparePartAccordionsContainer">
        <BundlesSection page={1} size={size} pageCallback={async () => explodedViewBundlesViewModel.bundles} />
        {mapping.map(i => <BundlesSection page={i + 2} key={i} size={size} pageCallback={callback}/>)}
    </div>
}

function BundlesSection({ page, size, pageCallback }: { page: number, size: number, pageCallback: (page: number) => Promise<Bundle[]> }) {
    const [items, setItems] = useState<Bundle[]>([]);

    const observer = useMemo(() => {
        const options = {
            root: document.rootElement,
            rootMargin: "200px 0px",
            threshold: 0,
        };

        const callback = async (entries: IntersectionObserverEntry[]) => {
            if (entries.some(e => e.isIntersecting) && items.length == 0) {
                const data = await pageCallback(page);
                setItems(data);
            }
        }

        return new IntersectionObserver(callback, options);
    }, []);

    useEffect(() => {
        const target = document.querySelectorAll(`.listPlaceholder_${page}`);
        target.forEach(item => observer.observe(item));
    }, [])

    return <>{
        items.length
        ? items.map(bundle => (
            <a key={bundle.contentLink} title={bundle.contentName} href={bundle.contentLink} className="sparePartsAccordion justify-content-space-between">
                    {bundle.imageUrl &&
                        <div className="sparePartsAccordionImage">
                            <img src={bundle.imageUrl + "?height=260&rsampler=bicubic&format=jpg"} alt={bundle.contentName} />
                        </div>
                    }
                    <div className="sparePartsAccordionTitle explodedViewSparePartsAccordionTitle">
                        <span>{bundle.contentName}</span>
                    </div>
                </a>
            ))
            : Array.from(Array(size).keys()).map(i => (
                <div key={i} className={`listPlaceholder_${page} sparePartsAccordionPlaceholder`}></div>
            ))
    }</>
}